import React from "react";
import Button from '@material-ui/core/Button';
import ManifestUtils from "../model/ManifestUtils";
import PuzzleTile from "./PuzzleTile";
import PuzzleModel from "../model/PuzzleModel";
import {
    Link
} from "react-router-dom";


export default class PuzzlePage extends React.Component {

    constructor(props) {
        super(props);
        this.size = 5;
        this.state = {
            manifest: '',
            manifestUri: null,
            puzzleModel: null
        }

        this.swap = this.swap.bind(this);
    }

    componentDidMount() {
        const manifestUri = this.props?.location?.state?.manifest;
        if (manifestUri) {
            const mu = new ManifestUtils();
            mu.manifest(manifestUri).then(
                manifest => {
                    const { image } = mu.manifestThumbnail(manifest);
                    const puzzleModel = new PuzzleModel(image, this.size, window.innerWidth, window.innerHeight);
                    this.setState({
                        manifestUri,
                        manifest,
                        puzzleModel
                    })});
        }
    }

    getTiles(puzzleModel) {
        const list = [];
        for (let y = 0; y < this.size; y++ )  {
            let row = [];
            for (let x = 0; x < this.size; x++ ) {
                const tile = puzzleModel.tile(x,y);
                row.push((<PuzzleTile key={tile.src} src={tile.src} pos={tile.currentPos} dropHandler={this.swap} alt={"puzzle tile #" + tile.currentPos} />))
            }
            list.push((<div className="puzzleRow" key={y}>{row}</div>));
        }
        return list;
    }

    swap(p1, p2) {
        this.state.puzzleModel.swap(p1,p2);
        this.setState({puzzleModel: this.state.puzzleModel});
    }

    select(p1) {

    }

    render() {
        const manifest = this.state.manifest;

        if (manifest) {
            const tiles = this.getTiles(this.state.puzzleModel);
            return (
                <div>
                    <div className="puzzleArea">
                        { tiles }
                    </div>
                    <div className="puzzleAction">
                        {this.state.puzzleModel.isCompleted()
                            ? <Button variant="contained" color="default" component={Link} to="/">Done! Pick a new puzzle...</Button>
                            : <Button variant="contained" color="primary" component={Link} to="/">I give up! Back to the puzzle list!</Button>
                        }
                    </div>
                </div>
            );
        } else {
            return ("<h1>No puzzle available</h1>");
        }

    }
}