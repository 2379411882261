import * as Manifesto from 'manifesto.js';
import sanitizeHtml from 'sanitize-html';

export default class ManifestUtils {

    /**
     * @param {String} uri
     * @returns {Promise<Manifest>}
     */
    async manifest(uri) {
        let response = await fetch(uri);
        const json = await response.json();
        return new Manifesto.Manifest(json);
    }

    /**
     * @param {Manifest} manifest
     * @returns {{image: Image, thumb: string, description: any, label: string}}
     */
    manifestThumbnail(manifest) {
        const sequence = manifest.getSequenceByIndex(0);
        const canvas = sequence.getCanvasByIndex(0);
        return this.canvasThumbnail(canvas);
    }

    /**
     * @param {Canvas} canvas
     * @returns {{image: Image, thumb: string, description: any, label: string}}
     */
    canvasThumbnail(canvas) {
        // get label for a11y
        const label = canvas.getDefaultLabel();
        // could theoretically have a description per Presentation API 2 section 3.1
        const description = canvas.getProperty('description') || label;
        // image is no required, so could be null; but our manifests should be OK
        const image = canvas.getImages()[0];
        const resource = image.getResource();
        const thumb = this.resourceThubmnail(resource);
        return {
            label,
            description,
            thumb,
            image
        }
    }

    /**
     * @param {Resource} resource
     * @returns {string} URL of thumbnail image
     */
    resourceThubmnail(resource) {
        // service is not required, so could be null; but our manifests should be OK
        const service = resource.getServices()[0];
        let quality = 'default.jpg'
        // Handle Image API v1
        if (service.context.startsWith('http://iiif.io/api/image/1/')) {
            quality = 'native.jpg'
        }
        const thumb = service.id + "/full/,400/0/" + quality;
        return thumb;
    }

    /**
     * @param {Manifest} manifest
     * @returns {string}
     */
    attribution(manifest) {
        return manifest.getAttribution().flatMap( a => sanitizeHtml(a.value, {allowedTags: [], allowedAttributes: {}, selfClosing: []}) );
    }

}
