import React from "react";

export default class PuzzleTile extends React.Component {

    onDragStart = (event) => {
        event.dataTransfer.setData("text", this.props.pos);
        let image = new Image();
        image.src = this.props.src;
        event.dataTransfer.setDragImage(event.target, 0, 0);
    }
    onDragOver = (event) => event.preventDefault();
    onDrop = (event) => {
        const p2 = (event.dataTransfer.getData("text"));
        this.props.dropHandler(this.props.pos, p2);
        event.preventDefault();
    }
    onTouchStart = (event) => {
        console.log(event);
        console.log(event.target);
        event.preventDefault();
    }

    render() {
        return (<img className="puzzleTile"
                     src={this.props.src}
                     alt={this.props.alt}
                     draggable="true"
                     onDragStart={e => this.onDragStart(e)}
                     onDragOver={e => this.onDragOver(e)}
                     onDrop={e => this.onDrop(e)}
                     onTouchStart={e => this.onTouchStart(e)}
                     onTouchStartCapture={e => this.onTouchStart(e)}
                     onTouchEnd={e => this.onTouchStart(e)}
                     onTouchMove={e => this.onTouchStart(e)}
        />);
    }
}