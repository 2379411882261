import React from 'react';
import {GridList} from '@material-ui/core';
import ManifestTile from "./ManifestTile";

export default class PuzzleSelector extends React.Component {
    tiles() {
        return this.props.uris.map(
            uri => {
                return (<ManifestTile manifest={uri} key={uri} />);
            }
        );
    }

    render() {
        return (
            <div>
                <h1>Puzzles!</h1>
                <h2>Powered by IIIF</h2>
                <GridList className="gridlist" cols={2}>
                    { this.tiles() }
                </GridList>
            </div>
        );
    }
}
