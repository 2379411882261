
export default class PuzzleModel {

    /**
     * @param {Image} image
     * @param {number} gridSize
     * @param {number} maxWidth
     * @param {number} maxHeight
     */
    constructor(image, gridSize, maxWidth, maxHeight) {
        const imageResouce = image.getResource();
        this.imageService = imageResouce.getServices()[0];
        this.imageId = this.imageService.id;
        this.gridSize = gridSize;
        this.height = imageResouce.getHeight() - ( imageResouce.getHeight()  % gridSize );
        this.width = imageResouce.getWidth() - (  imageResouce.getWidth()  % gridSize );

        // account for button, label
        maxHeight = maxHeight - 100;
        if ( (this.height / this.width) > (maxHeight / maxWidth) ) {
            this.scalePct = Math.min(maxHeight / this.height, 1);
        } else {
            this.scalePct = Math.min(maxWidth / this.width, 1);
        }

        // put all tiles in a single array
        this.tileMap = [];
        let sequence = 0;
        for (let row = 0; row < gridSize; row++) {
            for(let col = 0; col < gridSize; col++) {
                this.tileMap.push({
                    src: this.generateTile(row, col),
                    sequence
                });
                sequence++;
            }
        }
        this.scrambleTiles();
    }

    /**
     *
     * @param {number} p1
     * @param {number} p2
     */
    swap(p1, p2) {
        const t1 = this.tileMap[p1];
        t1.currentPos = p2;
        const t2 = this.tileMap[p2];
        t2.currentPos = p1;
        this.tileMap[p1] = this.tileMap[p2];
        this.tileMap[p2] = t1;
    }

    /**
     * @param {number} row
     * @param {number} col
     */
    tile(row, col) {
        return this.tileMap[row * this.gridSize + col];
    }

    regionWidth() {
        return this.width / this.gridSize;
    }

    regionHeight() {
        return this.height / this.gridSize;
    }

    tileWidth() {
        return Math.ceil(this.width * this.scalePct / this.gridSize);
    }

    tileHeight() {
        return Math.ceil(this.height * this.scalePct / this.gridSize);
    }

    /**
     * @param {number} col
     * @param {number} row
     */
    generateTile(col, row) {
        const xywh = [col * this.regionWidth(), row * this.regionHeight(), this.regionWidth(), this.regionHeight()].join(',');
        const size = this.tileWidth() + "," + this.tileHeight();
        const src = this.imageId + "/" + xywh + "/" + size + "/0/" + this.quality();
        return src;
    }

    isCompleted() {
        for (let pos = 0; pos < Math.pow(this.gridSize, 2); pos++) {
            if (this.tileMap[pos].sequence !== pos) {
                return false;
            }
        }
        return true;
    }

    quality() {
        let quality = 'default.jpg'
        if (this.imageService.context.startsWith('http://iiif.io/api/image/1/')) {
            quality = 'native.jpg'
        }
        return quality;
    }

    scrambleTiles() {
        const max = this.gridSize * this.gridSize;
        for(let pos = 0; pos < max; pos++) {
            const otherPos = Math.floor(Math.random() * max);
            this.swap(pos, otherPos);
        }
    }

}