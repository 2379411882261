import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "./components/PuzzleSelector";
import PuzzleSelector from "./components/PuzzleSelector";
import PuzzlePage from "./components/PuzzlePage";

function App() {

  const uris = [
      'https://manifests.britishart.yale.edu/manifest/5005',
      'https://manifests.britishart.yale.edu/manifest/722',
      'https://manifests.britishart.yale.edu/manifest/991',
    'https://manifests.britishart.yale.edu/manifest/1131',
    'http://manifests.britishart.yale.edu/manifest/38526',
    'http://manifests.britishart.yale.edu/manifest/5009',
    'http://manifests.britishart.yale.edu/manifest/10304',
    'http://manifests.britishart.yale.edu/manifest/586',
    'http://manifests.britishart.yale.edu/manifest/34',
    'http://oculus.harvardx.harvard.edu/manifests/huam:299843',
    'https://iiif.archivelab.org/iiif/mma_irises_436528/manifest.json',
    'http://purl.stanford.edu/cr853gt5311/iiif/manifest.json',
    'https://purl.stanford.edu/ch264fq0568/iiif/manifest.json',
    'http://manifests.britishart.yale.edu/manifest/1474',
  ];

  return (
      <Router>
        <div className="App">
            <Switch>
                <Route path="/puzzle" component={PuzzlePage} />
                <Route path="/">
                    <PuzzleSelector uris={uris} />
                </Route>
            </Switch>
        </div>
      </Router>
  );
}

export default App;
