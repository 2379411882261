import React from 'react';
import {GridListTile, GridListTileBar} from '@material-ui/core';
import { Link } from "react-router-dom";
import ManifestUtils from "../model/ManifestUtils";

export default class ManifestTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            thumb: "",
            label: "",
            description: ""
        }
    }

    componentDidMount() {
        this.setManifestThumbnail();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.setManifestThumbnail();
    }

    setManifestThumbnail() {
        const mu = new ManifestUtils();
        mu.manifest(this.props.manifest).then(
            manifest =>
                this.setState({
                    ...mu.manifestThumbnail(manifest),
                    title: manifest.getDefaultLabel(),
                    attribution: mu.attribution(manifest)
                })
        );
    }

    render() {
        return (
            <Link to={{
                pathname: '/puzzle',
                state: {manifest: this.props.manifest}
            }}>
            <GridListTile className="gridTile">
                <img src={this.state.thumb}  alt={this.state.label} />
                <GridListTileBar
                    title={this.state.title}
                    subtitle={<span>{this.state.attribution}</span>}
                    />
            </GridListTile>
            </Link>
        );
    }
}